import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'dashboard/basic-dashboard',
            // redirect: 'login/Login',
            component: () => import('@/layouts/Layout'),
            children: [
                // Components
                // {
                //     name: 'Alerts',
                //     path: 'pages/alerts',
                //     component: () => import('@/views/pages/Alerts'),
                // },

                // {
                //     name: 'Profile',
                //     path: 'pages/profile',
                //     component: () => import('@/views/pages/Profile'),
                // },

                // {
                //     name: 'Icons',
                //     path: 'pages/icons',
                //     component: () => import('@/views/pages/Icons'),
                // },

                // {
                //     name: 'TableSimple',
                //     path: 'pages/tables-simple',
                //     component: () => import('@/views/pages/TableSimple'),
                // },

                {
                    name: 'User Report',
                    path: 'dashboard/basic-dashboard',
                    component: () => import('@/views/dashboard/BasicDashboard'),
                },
                {
                    name: 'Error Report',
                    path: 'pages/error',
                    component: () => import('@/views/pages/Error'),
                },
                {
                    name: 'All Logs',
                    path: 'pages/alllogs',
                    component: () => import('@/views/pages/Alllogs'),
                },
                {
                    name: 'Test Page',
                    path: 'pages/test',
                    component: () => import('@/views/pages/test'),
                },
                // {
                //     name: 'Candidates',
                //     path: 'pages/candidates',
                //     component: () => import('@/views/pages/candidates'),
                // },

            ]
        },

    ],
})